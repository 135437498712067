import { Box, IconButton, Paper } from '@mui/material';
import { ChartsBlockTabType, ChartSeriesType } from 'pages/Insights/InsightsComponents/types';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import { Dispatch, SetStateAction } from 'react';
import { OpenInFull } from '@mui/icons-material';
import StockChart from './StockChart';
import { ChartSeries } from '../useChartSeries';

const StockChartsBlock = ({
  setFullScreen,
  tabs,
  activeTab,
  setActiveTab,
  layout,
  loading,
  error,
  chartSeries
}: {
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabs: ChartsBlockTabType[];
  layout?: { fullWidth?: boolean; height?: number };
  loading: boolean;
  error: boolean;
  chartSeries: ChartSeries[];
}) => {
  const { fullWidth, height } = layout || {};
  return (
    <Paper
      sx={theme => ({
        width: fullWidth ? '100%' : 'calc(50% - 12px)',
        [theme.breakpoints.down(1350)]: {
          width: '100%'
        }
      })}
    >
      <Tabs
        tabs={tabs.map(({ name }) => ({ name }))}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <TabPanel
        key={'name'}
        index={activeTab}
        value={activeTab}
      >
        <Box sx={{ mt: 1 }}>
          <Box
            className='chart-container'
            sx={{ position: 'relative ' }}
          >
            {loading ? null : (
              <Box sx={{ position: 'absolute', right: '30px', zIndex: 1 }}>
                <IconButton
                  onClick={() => {
                    setFullScreen(true);
                  }}
                  aria-label='open'
                >
                  <OpenInFull />
                </IconButton>
              </Box>
            )}
            <StockChart
              loading={loading}
              error={error}
              chartSeries={chartSeries}
              height={height}
            />
          </Box>{' '}
        </Box>
      </TabPanel>
    </Paper>
  );
};

export default StockChartsBlock;
