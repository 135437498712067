import { DateTextVariants } from 'components/DateText/useDateText';
import useTooltip from './useTooltip';
import { Theme } from '@mui/material';

const CHART_GRID_COLOR = '#333';

const useChartFunctions = ({}: { chartSeries: any; height?: number }) => {
  const { createTooltip } = useTooltip();

  return {
    createNavigator,
    createPlotOptions,
    createTooltipOption,
    createYAxis,
    createXAxis
  };

  function createNavigator(
    theme: Theme,
    formatDateString: (dateString: string, variant: DateTextVariants) => string
  ) {
    return {
      xAxis: {
        gridLineColor: theme.palette.info.dark,
        labels: {
          style: {
            color: theme.palette.text.primary
          },
          formatter: function (): string {
            // @ts-expect-error: highcharts rules.
            const timestamp = this.value;
            return formatDateString(new Date(timestamp).toJSON(), DateTextVariants.MONTH_YEAR);
          }
        }
      },
      enabled: true,
      scrollbar: { enabled: true, barBackgroundColor: theme.palette.info.dark },
      outlineColor: theme.palette.info.dark,
      handles: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.info.dark
      }
    };
  }

  function createPlotOptions(theme: Theme) {
    return {
      series: {
        dataLabels: {
          position: 'right',
          enabled: true,
          useHTML: true,
          formatter: function (): string | undefined {
            const point: any = this;
            const last = point.series.points[point.series.points.length - 1].index;
            if (point.point.index === last) {
              return `<div style="color: ${theme.palette.background.default}; display: inline-flex; padding: 0px 4px 0px 4px; justify-content: center;text-align: center;align-items: center;  width:55px;height:15px; color: #070818;font-size: 10px;font-style: normal;font-weight: 400;line-height: normal;background-color:${point.series.color};">${point.y}</div>`;
            }
          }
        }
      },
      line: {
        lineWidth: 2,
        marker: {
          radius: 1,
          enabled: true
        },
        states: {
          hover: {
            lineWidth: 3
          }
        }
      }
    };
  }

  function createTooltipOption(theme: Theme) {
    return {
      positioner: function (labelWidth: any, labelHeight: any, point: any): any {
        // @ts-expect-error: highcharts rules.
        const chart = this.chart;
        let x = point.plotX + chart.plotLeft - labelWidth / 2;
        let y = point.plotY + chart.plotTop - labelHeight - 10;

        const chartWidth = chart.plotWidth;

        if (x < chart.plotLeft) {
          x = chart.plotLeft;
        } else if (x + labelWidth > chart.plotLeft + chartWidth) {
          x = chart.plotLeft + chartWidth - labelWidth;
        }

        if (y < chart.plotTop) {
          y = point.plotY + chart.plotTop + 10;
        }

        return { x, y };
      },
      formatter: function (): string {
        // @ts-expect-error: highcharts rules.
        const allChartSeries: Highcharts.Series[] = this.series.chart.series;
        // @ts-expect-error: highcharts rules.
        const hoveredPointXValue = this.x;
        return createTooltip(allChartSeries, hoveredPointXValue);
      },
      outside: true,
      className: 'tooltip-stock-chart',
      enabled: true,
      shared: true,
      split: false,
      backgroundColor: CHART_GRID_COLOR,
      borderRadius: 0,
      useHTML: true,
      style: {
        zIndex: 1303,
        color: theme.palette.text.primary,
        fontFamily: 'Cairo, sans-serif'
      }
    };
  }

  function createYAxis(theme: Theme) {
    return {
      offset: 35,
      labels: {
        style: {
          color: theme.palette.text.primary,
          fontSize: '10px'
        },
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          return this.value.toFixed(2);
        }
      },
      gridLineColor: CHART_GRID_COLOR
    };
  }

  function createXAxis(
    formatDateString: (dateString: string, variant: DateTextVariants) => string,
    theme: Theme
  ) {
    return {
      overscroll: '2px',
      labels: {
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          return formatDateString(new Date(this.value).toJSON(), DateTextVariants.MONTH_YEAR) ?? '';
        },
        style: {
          color: theme.palette.text.primary,
          fontSize: '10px'
        }
      },
      gridLineColor: CHART_GRID_COLOR,
      lineColor: CHART_GRID_COLOR,
      tickColor: CHART_GRID_COLOR
    };
  }
};

export default useChartFunctions;
