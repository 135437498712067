import { useEffect, useRef, useState } from 'react';
import useStockOptions from './useStockOptions';
import { ChartSeries } from '../../useChartSeries';

const useStockChart = ({
  chartSeries,
  height
}: {
  chartSeries: ChartSeries[];
  height?: number;
}) => {
  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState(null);

  const { createOptions } = useStockOptions({
    chartSeries,
    height
  });

  const options = chartSeries ? createOptions() : null;

  useEffect(() => {
    if (chartComponentRef.current) {
      const charContainer: any = chartComponentRef.current;
      setChartObject(charContainer.chart);
    }
  }, [chartComponentRef.current]);

  useEffect(() => {
    chartComponentRef.current = null;
    setChartObject(null);
  }, [chartSeries]);

  return { options, chartObject, chartComponentRef };
};

export default useStockChart;
