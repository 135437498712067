import Highcharts from 'highcharts/highstock';
import { SelectChangeEvent } from '@mui/material';
import { useState } from 'react';

const useLegendDropdown = ({ chart }: { chart: Highcharts.Chart }) => {
  const [seriesName, setSeriesName] = useState<Highcharts.Series[]>();
  const allChartSeries = chart.series.filter(
    (series: Highcharts.Series) => !series.name.includes('Navigator')
  );

  const handleLegendItemClick = (e: SelectChangeEvent<unknown>, allItems: Highcharts.Series[]) => {
    const value: any = e.target.value;
    if (typeof value === 'object') {
      allItems.map(item => {
        if (!value?.includes(item)) {
          item.setVisible(false);
        } else {
          item.setVisible(true);
        }
      });
      setSeriesName(allItems.filter(item => item.visible));
    }
  };

  return { seriesName, allChartSeries, handleLegendItemClick };
};

export default useLegendDropdown;
