import useChartsBlock from './useChartsBlock';
import { ChartsBlockTabType } from 'pages/Insights/InsightsComponents/types';
import FullScrreenChartsBlock from './FullScreenChartsBlock';
import StockChartsBlock from './StockChartsBlock';

import Highcharts from 'highcharts/highstock';
import indicatorsAll from 'highcharts/indicators/indicators-all';
import priceIndicator from 'highcharts/modules/price-indicator';
import fullScreen from 'highcharts/modules/full-screen';
import annotationsAdvanced from 'highcharts/modules/annotations-advanced.js';
import exporting from 'highcharts/modules/exporting';
import stockTools from 'highcharts/modules/stock-tools.js';
import indicators from 'highcharts/indicators/indicators';
import './style.css';

indicators(Highcharts);
indicatorsAll(Highcharts);
annotationsAdvanced(Highcharts);
priceIndicator(Highcharts);
fullScreen(Highcharts);
stockTools(Highcharts);
exporting(Highcharts);

const ChartsBlock = ({
  tabs,
  layout
}: {
  tabs: ChartsBlockTabType[];
  layout?: { fullWidth?: boolean; height?: number };
}) => {
  const { activeTab, setActiveTab, fullScreen, setFullScreen, loading, error, chartSeries } =
    useChartsBlock(tabs);

  if (fullScreen) {
    return (
      <FullScrreenChartsBlock
        loading={loading}
        error={error}
        chartSeries={chartSeries}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
      />
    );
  } else {
    return (
      <StockChartsBlock
        loading={loading}
        error={error}
        chartSeries={chartSeries ?? []}
        setFullScreen={setFullScreen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
        layout={layout}
      />
    );
  }
};

export default ChartsBlock;
