import { AppBar, Box, Dialog, IconButton, Paper, Slide } from '@mui/material';
import { ChartsBlockTabType } from 'pages/Insights/InsightsComponents/types';
import Tabs from 'components/Tabs';
import { Dispatch, forwardRef, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from 'notistack';
import FullScreenChart from './FullScreenChart';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction='up'
      ref={ref}
      {...props}
    />
  );
});

const FullScrreenChartsBlock = ({
  fullScreen,
  setFullScreen,
  tabs,
  activeTab,
  setActiveTab,
  loading,
  error,
  chartSeries
}: {
  fullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabs: ChartsBlockTabType[];
  loading: boolean;
  error: boolean;
  chartSeries: any;
}) => {
  return (
    <Dialog
      fullScreen
      open={fullScreen}
      onClose={() => setFullScreen(false)}
      TransitionComponent={Transition}
      style={{ position: 'absolute' }}
    >
      <AppBar
        sx={{
          height: 48,
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Tabs
          tabs={tabs.map(({ name }) => ({ name }))}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <IconButton
          sx={{ width: 48 }}
          edge='start'
          color='inherit'
          onClick={() => setFullScreen(false)}
          aria-label='close'
        >
          <CloseIcon />
        </IconButton>
      </AppBar>
      <Paper
        sx={{
          width: '100%'
        }}
      >
        <Box
          className='chart-container'
          sx={{ position: 'relative ' }}
        >
          <FullScreenChart
            error={error}
            loading={loading}
            chartSeries={chartSeries}
          />
        </Box>
      </Paper>
    </Dialog>
  );
};

export default FullScrreenChartsBlock;
