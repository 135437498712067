import { useEffect, useRef, useState } from 'react';
import useFullScreenOptions from './useFullScreenOptions';

const useFullScreenChart = ({ chartSeries }: { chartSeries: any }) => {
  const height = window.innerHeight - 300;
  const { createOptions } = useFullScreenOptions({ chartSeries, height });
  const options = chartSeries ? createOptions() : null;
  options?.chart ? (options.chart.plotBackgroundColor = 'white') : null;

  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState(null);
  console.log(chartObject);
  useEffect(() => {
    if (chartComponentRef.current) {
      const chartRef: any = chartComponentRef.current;
      setChartObject(chartRef.chart);
    }
  }, [chartComponentRef.current]);

  return { chartObject, options, chartComponentRef };
};

export default useFullScreenChart;
