import { useTheme } from '@mui/material';
import useDateText, { DateTextVariants } from 'components/DateText/useDateText';

const useTooltip = () => {
  const { formatDateString } = useDateText();
  const theme = useTheme();

  const findValueOrBefore = (points: Highcharts.Point[], xValue: number) => {
    let low = 0;
    let high = points?.length - 1;
    if (xValue < points[0]?.x) {
      return null;
    }
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);

      if (points[mid]?.x === xValue) {
        return points[mid];
      } else if (points[mid]?.x < xValue) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    return points[high];
  };

  const createTooltip = (allChartSeries: Highcharts.Series[], hoveredPointXValue: any): string => {
    allChartSeries = allChartSeries.filter(series => !series.name.includes('Navigator'));

    const points: Highcharts.Point[] = allChartSeries
      .map(series => {
        let nearestPoint: Highcharts.Point | null;
        nearestPoint = findValueOrBefore(series.points, hoveredPointXValue);
        return nearestPoint;
      })
      .filter(x => !!x);

    const nameColumnHeader = '<div style="width: 152px;">Name</div>';

    const header: string =
      '<div style="display: flex; flex-direction: column; width:350px;">' +
      '<div style="font-size: 8px; display: flex; font-weight: 400;justify-content: space-between;">' +
      nameColumnHeader +
      '<div style="width: 65px;">PeriodAbs</div><div style="width: 65px;">Last date</div><div style="width: 10%;"></div>' +
      '</div>';

    const body = points
      .map(point => {
        // @ts-expect-error: highcharts rules.
        const custom = point.custom;
        const date = formatDateString(new Date(point.x).toJSON(), DateTextVariants.FULL);
        const nameColumn = `<div style="width: 152px; padding-right: 8px; border-right: 1px solid gray; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">${point.series.name}</div>`;
        return (
          '<div style="font-size: 10px; font-style: normal;font-weight: 500;justify-content: space-between;height: 19px; display: flex;">' +
          nameColumn +
          `<div style="width: 65px; padding-left: 8px; padding-right: 8px;">` +
          (custom?.periodAbs ? custom.periodAbs : '') +
          '</div>' +
          '<div style="width: 65px; padding-left: 8px; border-left: 1px solid gray; ">' +
          date +
          '</div>' +
          '<div style="height 19px; padding-left:8px; padding-top:3px"><div style="background-color:' +
          point.series.color +
          '; color:' +
          theme.palette.background.default +
          '; font-size:10px; line-height:13px; height:14px; width:55px; padding-left:4px; padding-right:4px; text-align: start;">' +
          point.y +
          '</div></div></div>'
        );
      })
      .join('');
    return header + body;
  };

  return { createTooltip };
};

export default useTooltip;
