import Highcharts, { setOptions } from 'highcharts/highstock';
import FullScreenLegend from './FullScreenLegend';
import useFullScreenChart from './useFullScreenChart';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@mui/material';
import { DEFAULT_CHART_HEIGHT } from 'utils/constants';
import Loader from 'components/Loader';
const FullScreenChart = ({
  chartSeries,
  error,
  loading
}: {
  chartSeries: any;
  error: boolean;
  loading: boolean;
}) => {
  const { chartObject, options, chartComponentRef } = useFullScreenChart({ chartSeries });

  if (error)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: DEFAULT_CHART_HEIGHT
        }}
      >
        <Typography color='info'>
          An error occurred while retrieving your data. Our team is looking into it.
        </Typography>
        <Typography color='info'>Please try again shortly.</Typography>
      </Box>
    );

  if (loading)
    return (
      <Box sx={{ height: DEFAULT_CHART_HEIGHT }}>
        <Loader />
      </Box>
    );

  return (
    <div
      style={{ paddingRight: 40 }}
      className='stock-chart-fullscreeen'
    >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
        ref={chartComponentRef}
      />
      {chartObject && <FullScreenLegend chart={chartObject} />}
    </div>
  );
};
export default FullScreenChart;
