import useDateText from 'components/DateText/useDateText';
import { useTheme } from '@mui/material';
import createRangeSelector from '../../useRangeSelector';
import { DEFAULT_CHART_HEIGHT } from 'utils/constants';
import useChartFunctions from '../../useChartFunctions';

const useFullScreenOptions = ({ chartSeries, height }: { chartSeries: any; height?: number }) => {
  const { formatDateString } = useDateText();
  const theme = useTheme();
  const { createNavigator, createPlotOptions, createTooltipOption, createYAxis, createXAxis } =
    useChartFunctions({ chartSeries, height });
  const createOptions = () => {
    return {
      chart: {
        plotBackgroundColor: theme.palette.background.paper,
        type: 'line',
        backgroundColor: theme.palette.background.paper,
        height: height || DEFAULT_CHART_HEIGHT,
        style: {
          fontFamily: 'Cairo, sans-serif'
        },
        zooming: {
          type: 'x'
        }
      },
      exporting: {
        enabled: true,
        filename: 'custom-chart-name',
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
          }
        }
      },
      stockTools: {
        gui: {
          buttons: [
            'indicators',
            'separator',
            'simpleShapes',
            'lines',
            'crookedLines',
            'measure',
            'advanced',
            'toggleAnnotations',
            'separator',
            'verticalLabels',
            'flags',
            'separator',
            'zoomChange',
            'separator',
            'currentPriceIndicator'
          ]
        }
      },
      plotHeight: (height || DEFAULT_CHART_HEIGHT) - 30,
      xAxis: createXAxis(formatDateString, theme),
      yAxis: createYAxis(theme),
      tooltip: createTooltipOption(theme),
      plotOptions: createPlotOptions(theme),
      series: chartSeries,
      legend: { enabled: false },
      navigator: createNavigator(theme, formatDateString),
      rangeSelector: createRangeSelector(theme, chartSeries),
      credits: {
        enabled: false
      }
    };
  };

  return { createOptions };
};

export default useFullScreenOptions;
