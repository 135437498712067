import { useEffect, useState } from 'react';
import { ChartSeriesType } from '../types';
import useChartSeries from './useChartSeries';

const useChartsBlock = (tabs: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  const [templateChartSeries, setTemplateChartSeries] = useState<ChartSeriesType[]>();

  const { loading, error, chartSeries } = useChartSeries(templateChartSeries);

  useEffect(() => {
    setActiveTab(0);
    setTemplateChartSeries(tabs[0].series);
  }, []);

  useEffect(() => {
    setTemplateChartSeries(tabs[activeTab].series);
  }, [activeTab]);

  return {
    activeTab,
    setActiveTab,
    fullScreen,
    setFullScreen,
    loading,
    error,
    chartSeries
  };
};

export default useChartsBlock;
